import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import TabsPage from '@/views/tabs.vue';
import Launcher from '@/views/launcher.vue';
import LanguagePicker from '@/views/language-picker.vue';
import LinkAccount from '@/views/link-account.vue';
import FavoritePlaces from '@/views/favorite-places.vue'; // Corrected from FovitePlaces
import Notifications from '@/views/notifications.vue'; // Corrected from notifications
import Setting from '@/views/setting.vue';
import App from '@/app.vue';
import { useClient } from '@core/supabase/client';
import { App as CapacitorApp, URLOpenListenerEvent } from '@capacitor/app';

const client = useClient();

CapacitorApp.addListener(`appUrlOpen`, async(event: URLOpenListenerEvent) => {
    console.log(`App opened with URL: ${event.url}`);
    const slug = event.url.split(`.app`).pop();
    if (slug) {
        const accessTokenMatch = slug.match(/access_token=([^&]*)/);
        const refreshTokenMatch = slug.match(/refresh_token=([^&]*)/);
        const accessToken = accessTokenMatch ? accessTokenMatch[1] : undefined;
        const refreshToken = refreshTokenMatch ? refreshTokenMatch[1] : undefined;
        if (accessToken && refreshToken) {
            console.log(`Access Token: ${slug}`);
            console.log(`URL:`, event.url);
            const { data, error } = await client.auth.setSession({
                access_token: accessToken,
                refresh_token: refreshToken,
            });

            router.push(`/places`);
            return;
        }
        router.push({
            path: slug,
        });
    }
});

async function beforeEnterAuthView(to: RouteLocationNormalized) {
    const { error, data } = await client.auth.getSession();
    if (!error && data.session) return `/places`;
}

const routes: Array<RouteRecordRaw> = [
    {
        path: `/`,
        component: Launcher,
        async beforeEnter(to) {
            const { error, data } = await client.auth.getSession();
            if (!error && data.session) return `/places`;
        },
    },
    {
        path: `/language`,
        component: LanguagePicker,
    },

    {
        path: `/auth`,
        component: App,
        children: [
            {
                path: `email`,
                component: () => import(`@/views/auth/email.vue`),
            },
        ],
    },
    {
        path: `/auth/email`,
        component: () => import(`@/views/auth/email.vue`),
        beforeEnter: beforeEnterAuthView,
    },
    {
        path: `/auth/otp`,
        component: () => import(`@/views/auth/otp.vue`),
        beforeEnter: beforeEnterAuthView,
    },
    {
        path: `/`,
        component: TabsPage,
        children: [
            {
                path: `places/:id`,
                component: () => import(`@/views/facilities/single.vue`),
                name: `facility-details`,
            },
            {
                path: `map`,
                component: () => import(`@/views/map.vue`),
            },
            {
                path: `places`,
                component: () => import(`@/views/places.vue`),
            },
            {
                path: `account`,
                component: () => import(`@/views/account.vue`),
            },
            {
                path: `feed`,
                component: () => import(`@/views/feed.vue`),
            },
            {
                path: `account/settings`,
                component: () => import(`@/views/account/settings.vue`),
            },
            {   path: `account/setting`,
                component: Setting,
            },
            {
                path: `link-account`,
                component: () => import(`@/views/link-account.vue`),
            },
            {
                path: `notifications`,
                component: () => import(`@/views/notifications.vue`),
            },
            {
                path: `favorite-places`,
                component: () => import(`@/views/favorite-places.vue`),
            },
        ],
        async beforeEnter(to) {
            const { error, data } = await client.auth.getSession();
            if (error || !data.session) return `/`;
            if (to.path === `/`) return `/places`;
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
