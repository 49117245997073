/* unplugin-vue-components disabled */export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "pl": {
        "listTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miejsca"])},
        "mapTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapa"])},
        "feedTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feed"])},
        "profileTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])}
      },
      "en": {
        "listTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Places"])},
        "mapTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
        "feedTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feed"])},
        "profileTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])}
      }
    }
  })
}