"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFetch = void 0;
var vue_1 = require("vue");
var client_1 = require("./client");
var radash_1 = require("radash");
function useFetch(_a) {
    var _this = this;
    var endpoint = _a.endpoint, params = _a.params, callback = _a.callback;
    var supabase = (0, client_1.useClient)();
    var _abortController = new AbortController();
    var _initialized = false;
    var items = (0, vue_1.ref)([]);
    var pending = (0, vue_1.ref)(true);
    var meta = (0, vue_1.ref)();
    var builderCreator = function () { return supabase.from(endpoint).select("*", { count: "exact" }); };
    var fetch = function () { return __awaiter(_this, void 0, void 0, function () {
        var builder, query_1, ftsCreator, response, property;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    builder = builderCreator();
                    if (!params.value.query) return [3 /*break*/, 2];
                    query_1 = params.value.query;
                    ftsCreator = function () { return builderCreator().textSearch("fts", "'".concat(query_1, "'"), { type: "websearch", config: "english" }); };
                    return [4 /*yield*/, ftsCreator()];
                case 1:
                    response = _a.sent();
                    builder = response.count ? ftsCreator() : builder.or(["name.ilike.", "%", query_1, "%"].join(""));
                    _a.label = 2;
                case 2:
                    for (property in (0, radash_1.omit)(params.value, ["page", "per_page", "query"])) {
                        if (!params.value[property])
                            continue;
                        builder = builder.or([property, params.value[property]].join("."));
                    }
                    return [2 /*return*/, builder.then(function (_a) {
                            var data = _a.data, count = _a.count;
                            items.value = data;
                            meta.value = { count: count };
                            _initialized = true;
                            callback({ data: data, meta: { count: count } }, params.value);
                            pending.value = false;
                        })];
            }
        });
    }); };
    (0, vue_1.watch)(params, function (now, then) {
        if (_initialized)
            _abortController.abort();
        _abortController = new AbortController();
        setTimeout(fetch, 0);
    }, { deep: true });
    var promise = new Promise(function (resolve) {
        setTimeout(function () { return fetch().then(resolve); }, 0);
    });
    return { items: items, meta: meta, refetch: fetch, pending: pending };
}
exports.useFetch = useFetch;
