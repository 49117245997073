/* unplugin-vue-components disabled */export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "pl": {
        "viewName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Język"])}
      },
      "en": {
        "viewName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])}
      }
    }
  })
}