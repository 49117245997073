"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) {
  return (0, vue_2.pushScopeId)("data-v-7383ae08"), n = n(), (0, vue_2.popScopeId)(), n;
};
var _hoisted_1 = {
  class: "w-full"
};
var _hoisted_2 = ["for"];
var _hoisted_3 = {
  class: "flex items-center"
};
var _hoisted_4 = ["src"];
var _hoisted_5 = {
  class: "text-grey-100"
};
var _hoisted_6 = ["id", "value", "onClick"];
var _hoisted_7 = {
  key: 0,
  class: "radio-indicator"
};
var _hoisted_8 = {
  key: 1,
  class: "border-grey-green h-6 w-6 rounded-xl border"
};
var usa_png_1 = require("../assets/usa.png");
var pl_png_1 = require("../assets/pl.png");
var vue_i18n_1 = require("vue-i18n");
var vue_router_1 = require("vue-router");
var i18n_1 = require("@/i18n");
exports.default = (0, vue_1.defineComponent)({
  __name: 'language-picker',
  setup: function (__props) {
    var _a = (0, vue_i18n_1.useI18n)({
        inheritLocale: true,
        useScope: "local"
      }),
      t = _a.t,
      locale = _a.locale;
    var router = (0, vue_router_1.useRouter)();
    var items = [{
      nameEn: "Polish",
      namePl: "Polski",
      code: "pl",
      flag: pl_png_1.default
    }, {
      nameEn: "English (US)",
      namePl: "Angielski",
      code: "en",
      flag: usa_png_1.default
    }];
    var getCurrentTagName = function (item) {
      return locale.value === "pl" ? item.namePl : item.nameEn;
    };
    var set = function (value) {
      i18n_1.default.global.locale.value = value;
      locale.value = value;
      localStorage.setItem("locale", value);
    };
    var goBack = function () {
      router.go(-1);
    };
    return function (_ctx, _cache) {
      var _component_topbar = (0, vue_2.resolveComponent)("topbar");
      var _component_ion_content = (0, vue_2.resolveComponent)("ion-content");
      var _component_ion_page = (0, vue_2.resolveComponent)("ion-page");
      return (0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_ion_page, {
        class: "flex justify-start"
      }, {
        default: (0, vue_2.withCtx)(function () {
          return [(0, vue_2.createVNode)(_component_topbar, {
            title: (0, vue_2.unref)(t)('viewName')
          }, null, 8, ["title"]), (0, vue_2.createVNode)(_component_ion_content, null, {
            default: (0, vue_2.withCtx)(function () {
              return [(0, vue_2.createElementVNode)("div", _hoisted_1, [((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(items, function (item) {
                return (0, vue_2.createElementVNode)("div", {
                  key: item.code,
                  class: "flex flex-col justify-between px-6 py-3"
                }, [(0, vue_2.createElementVNode)("label", {
                  for: item.code,
                  class: (0, vue_2.normalizeClass)(["radio-label font-nure border-grey-green active:border-yellow-prime-600 focus-within:border-yellow-prime-600 flex cursor-pointer items-center justify-between rounded-2xl border p-4", {
                    'border-yellow-prime-600': item.code === (0, vue_2.unref)(locale)
                  }])
                }, [(0, vue_2.createElementVNode)("div", _hoisted_3, [(0, vue_2.createElementVNode)("img", {
                  src: item.flag,
                  alt: "flag",
                  class: "mr-3 rounded-xl bg-[#25231A] p-4"
                }, null, 8, _hoisted_4), (0, vue_2.createElementVNode)("span", _hoisted_5, (0, vue_2.toDisplayString)(getCurrentTagName(item)), 1)]), (0, vue_2.createElementVNode)("input", {
                  id: item.code,
                  type: "radio",
                  value: item.code === (0, vue_2.unref)(locale),
                  class: "radio-input border-grey-green h-6 w-6 border",
                  onClick: function ($event) {
                    return set(item.code);
                  }
                }, null, 8, _hoisted_6), (0, vue_2.unref)(locale) === item.code ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_7)) : (0, vue_2.createCommentVNode)("", true), (0, vue_2.unref)(locale) !== item.code ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_8)) : (0, vue_2.createCommentVNode)("", true)], 10, _hoisted_2)]);
              }), 64))])];
            }),
            _: 1
          })];
        }),
        _: 1
      });
    };
  }
});