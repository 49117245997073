"use strict";

require("core-js/modules/es.symbol.description.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = {
  class: "mt-6 flex flex-col gap-4 px-6"
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = ["textContent"];
var _hoisted_4 = {
  class: "ion-padding bg-black-prime h-full"
};
var _hoisted_5 = {
  class: "mt-4 flex justify-end gap-8"
};
var vue_3 = require("vue");
var vue_router_1 = require("vue-router");
var vue_i18n_1 = require("vue-i18n");
var icons_1 = require("ionicons/icons");
var user_data_1 = require("@core/supabase/user-data");
var client_1 = require("@core/supabase/client");
var use_toast_1 = require("@core/use-toast");
exports.default = (0, vue_1.defineComponent)({
  __name: 'setting',
  setup: function (__props) {
    var _this = this;
    var router = (0, vue_router_1.useRouter)();
    var _a = (0, user_data_1.default)(),
      user = _a.user,
      logout = _a.logout;
    var client = (0, client_1.useClient)();
    var showToast = (0, use_toast_1.useToast)().presentToast;
    var _b = (0, vue_i18n_1.useI18n)({
        inheritLocale: true,
        useScope: "local"
      }),
      t = _b.t,
      locale = _b.locale;
    var isModalOpen = (0, vue_3.ref)(false);
    var getItemLabel = function (item) {
      return locale.value === "pl" ? item.labelPl : item.labelEn;
    };
    var confirmDeleteAccount = function () {
      return __awaiter(_this, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
          switch (_b.label) {
            case 0:
              isModalOpen.value = false;
              _b.label = 1;
            case 1:
              _b.trys.push([1, 3, 4, 5]);
              return [4 /*yield*/, client.functions.invoke("delete-user")];
            case 2:
              _b.sent();
              return [3 /*break*/, 5];
            case 3:
              _a = _b.sent();
              showToast(t("deleteError"), 3000, "bottom");
              return [3 /*break*/, 5];
            case 4:
              logout();
              router.push("/");
              return [7 /*endfinally*/];
            case 5:
              return [2 /*return*/];
          }
        });
      });
    };

    var deleteAccount = function () {
      isModalOpen.value = true;
    };
    var items = [{
      labelEn: "Delete Account",
      labelPl: "Usu\u0144 Konto",
      icon: icons_1.trashOutline,
      onTap: deleteAccount
    }];
    return function (_ctx, _cache) {
      var _component_topbar = (0, vue_2.resolveComponent)("topbar");
      var _component_ion_icon = (0, vue_2.resolveComponent)("ion-icon");
      var _component_ion_content = (0, vue_2.resolveComponent)("ion-content");
      var _component_ion_modal = (0, vue_2.resolveComponent)("ion-modal");
      var _component_ion_page = (0, vue_2.resolveComponent)("ion-page");
      return (0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_ion_page, {
        class: "flex justify-start"
      }, {
        default: (0, vue_2.withCtx)(function () {
          return [(0, vue_2.createVNode)(_component_topbar, {
            title: (0, vue_2.unref)(t)('title')
          }, null, 8, ["title"]), (0, vue_2.createVNode)(_component_ion_content, {
            class: "bg-black-prime"
          }, {
            default: (0, vue_2.withCtx)(function () {
              return [(0, vue_2.createElementVNode)("div", _hoisted_1, [((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(items, function (item) {
                return (0, vue_2.createElementVNode)("div", {
                  key: item.label,
                  class: "flex h-14 cursor-pointer items-center gap-4",
                  onClick: item.onTap
                }, [typeof item.icon === 'string' ? ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_ion_icon, {
                  key: 0,
                  icon: item.icon,
                  class: "text-grey-500 text-[20px]"
                }, null, 8, ["icon"])) : (0, vue_2.createCommentVNode)("", true), (0, vue_2.createElementVNode)("div", {
                  class: "body-m-medium text-grey-100 mr-auto",
                  textContent: (0, vue_2.toDisplayString)(getItemLabel(item))
                }, null, 8, _hoisted_3)], 8, _hoisted_2);
              }), 64))])];
            }),
            _: 1
          }), (0, vue_2.createVNode)(_component_ion_modal, {
            "initial-breakpoint": 0.25,
            breakpoints: [0, 0.25, 0.5, 0.75],
            "is-open": isModalOpen.value,
            onDidDismiss: _cache[1] || (_cache[1] = function ($event) {
              return isModalOpen.value = false;
            })
          }, {
            default: (0, vue_2.withCtx)(function () {
              return [(0, vue_2.createVNode)(_component_ion_content, null, {
                default: (0, vue_2.withCtx)(function () {
                  return [(0, vue_2.createElementVNode)("div", _hoisted_4, [(0, vue_2.createElementVNode)("h2", null, (0, vue_2.toDisplayString)((0, vue_2.unref)(t)("deleteAccount")), 1), (0, vue_2.createElementVNode)("div", _hoisted_5, [(0, vue_2.createElementVNode)("button", {
                    class: "text-grey-50 body-m-medium",
                    onClick: _cache[0] || (_cache[0] = function ($event) {
                      return isModalOpen.value = false;
                    })
                  }, (0, vue_2.toDisplayString)((0, vue_2.unref)(t)("cancel")), 1), (0, vue_2.createElementVNode)("button", {
                    class: "text-grey-50 body-m-medium",
                    onClick: confirmDeleteAccount
                  }, (0, vue_2.toDisplayString)((0, vue_2.unref)(t)("confirm")), 1)])])];
                }),
                _: 1
              })];
            }),
            _: 1
          }, 8, ["initial-breakpoint", "breakpoints", "is-open"])];
        }),
        _: 1
      });
    };
  }
});