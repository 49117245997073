/* unplugin-vue-components disabled */export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "pl": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia"])},
        "deleteAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy na pewno chcesz usunąć konto?"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])},
        "deleteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd podczas usuwania konta"])}
      },
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
        "deleteAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete your account?"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "deleteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error deleting user account"])}
      }
    }
  })
}