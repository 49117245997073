/* unplugin-vue-components disabled */"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var vue_1 = require("vue");
var vue_2 = require("vue");
var home_vue_1 = require("@ui/icons/home.vue");
var map_vue_1 = require("@ui/icons/map.vue");
var sheet_vue_1 = require("@ui/icons/sheet.vue");
var profile_vue_1 = require("@ui/icons/profile.vue");
var hover_1 = require("@core/directives/hover");
exports.default = (0, vue_1.defineComponent)({
  __name: 'tabs',
  setup: function (__props) {
    var t = useI18n({
      inheritLocale: true,
      useScope: "local"
    }).t;
    return function (_ctx, _cache) {
      var _component_ion_router_outlet = (0, vue_2.resolveComponent)("ion-router-outlet");
      var _component_ion_label = (0, vue_2.resolveComponent)("ion-label");
      var _component_ion_tab_button = (0, vue_2.resolveComponent)("ion-tab-button");
      var _component_ion_tab_bar = (0, vue_2.resolveComponent)("ion-tab-bar");
      var _component_ion_tabs = (0, vue_2.resolveComponent)("ion-tabs");
      var _component_ion_page = (0, vue_2.resolveComponent)("ion-page");
      return (0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_ion_page, null, {
        default: (0, vue_2.withCtx)(function () {
          return [(0, vue_2.createVNode)(_component_ion_tabs, null, {
            default: (0, vue_2.withCtx)(function () {
              return [(0, vue_2.createVNode)(_component_ion_router_outlet), (0, vue_2.createVNode)(_component_ion_tab_bar, {
                slot: "bottom",
                class: "border-yellow-prime-darker border-1 bg-black-prime h-16 border-t"
              }, {
                default: (0, vue_2.withCtx)(function () {
                  return [(0, vue_2.withDirectives)(((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_ion_tab_button, {
                    tab: "feed",
                    href: "/feed"
                  }, {
                    default: (0, vue_2.withCtx)(function () {
                      return [(0, vue_2.createVNode)(home_vue_1.default, {
                        class: "text-yellow-prime h-6",
                        "aria-hidden": "true"
                      }), (0, vue_2.createVNode)(_component_ion_label, {
                        class: "body-xs-bold text-yellow-prime mt-1"
                      }, {
                        default: (0, vue_2.withCtx)(function () {
                          return [(0, vue_2.createTextVNode)((0, vue_2.toDisplayString)((0, vue_2.unref)(t)("feedTab")), 1)];
                        }),
                        _: 1
                      })];
                    }),
                    _: 1
                  })), [[(0, vue_2.unref)(hover_1.default)]]), (0, vue_2.withDirectives)(((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_ion_tab_button, {
                    tab: "map",
                    href: "/map"
                  }, {
                    default: (0, vue_2.withCtx)(function () {
                      return [(0, vue_2.createVNode)(map_vue_1.default, {
                        class: "text-yellow-prime h-6",
                        "aria-hidden": "true"
                      }), (0, vue_2.createVNode)(_component_ion_label, {
                        class: "body-xs-bold text-yellow-prime mt-1"
                      }, {
                        default: (0, vue_2.withCtx)(function () {
                          return [(0, vue_2.createTextVNode)((0, vue_2.toDisplayString)((0, vue_2.unref)(t)("mapTab")), 1)];
                        }),
                        _: 1
                      })];
                    }),
                    _: 1
                  })), [[(0, vue_2.unref)(hover_1.default)]]), (0, vue_2.withDirectives)(((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_ion_tab_button, {
                    tab: "places",
                    href: "/places"
                  }, {
                    default: (0, vue_2.withCtx)(function () {
                      return [(0, vue_2.createVNode)(sheet_vue_1.default, {
                        class: "text-yellow-prime h-6",
                        "aria-hidden": "true"
                      }), (0, vue_2.createVNode)(_component_ion_label, {
                        class: "body-xs-bold text-yellow-prime mt-1"
                      }, {
                        default: (0, vue_2.withCtx)(function () {
                          return [(0, vue_2.createTextVNode)((0, vue_2.toDisplayString)((0, vue_2.unref)(t)("listTab")), 1)];
                        }),
                        _: 1
                      })];
                    }),
                    _: 1
                  })), [[(0, vue_2.unref)(hover_1.default)]]), (0, vue_2.withDirectives)(((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_ion_tab_button, {
                    tab: "account",
                    href: "/account"
                  }, {
                    default: (0, vue_2.withCtx)(function () {
                      return [(0, vue_2.createVNode)(profile_vue_1.default, {
                        class: "text-yellow-prime h-6",
                        "aria-hidden": "true"
                      }), (0, vue_2.createVNode)(_component_ion_label, {
                        class: "body-xs-bold text-yellow-prime mt-1"
                      }, {
                        default: (0, vue_2.withCtx)(function () {
                          return [(0, vue_2.createTextVNode)((0, vue_2.toDisplayString)((0, vue_2.unref)(t)("profileTab")), 1)];
                        }),
                        _: 1
                      })];
                    }),
                    _: 1
                  })), [[(0, vue_2.unref)(hover_1.default)]])];
                }),
                _: 1
              })];
            }),
            _: 1
          })];
        }),
        _: 1
      });
    };
  }
});