import { createI18n, LocaleMessages, VueMessageType } from 'vue-i18n';

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages(): LocaleMessages<VueMessageType> {
    // eslint-disable-next-line unicorn/prefer-module
    const locales = require.context(`./locales`, true, /[\s\w,-]+\.json$/i);
    const messages: LocaleMessages<VueMessageType> = {};
    for (const key of locales.keys()) {
        const matched = key.match(/([\w-]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key).default;
        }
    }
    return messages;
}

export default createI18n({
    legacy: false,
    locale: process.env.VUE_APP_I18N_LOCALE || `en`,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || `en`,
    messages: loadLocaleMessages(),
});
