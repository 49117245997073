"use strict";

require("core-js/modules/es.object.from-entries.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.replace.js");
var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useIndex = exports.BaseClass = void 0;
var vue_1 = require("vue");
var avolitty_hasher_1 = require("avolitty-hasher");
var pluralize_1 = require("pluralize");
var core_1 = require("@vueuse/core");
var fetch_1 = require("./fetch");
var client_1 = require("./client");
var radash_1 = require("radash");
var luxon_1 = require("luxon");
function hash(data) {
  return (0, avolitty_hasher_1.default)(data, {
    ignoreObjectKeySortOrder: true,
    outputLength: 8
  });
}
var BaseClass = /** @class */function () {
  function BaseClass(data) {
    Object.assign(this, data);
  }
  return BaseClass;
}();
exports.BaseClass = BaseClass;
// eslint-disable-next-line sonarjs/cognitive-complexity
function useIndex(_a) {
  var entity = _a.entity,
    _b = _a.endpoint,
    endpoint = _b === void 0 ? entity : _b,
    _c = _a.single,
    single = _c === void 0 ? {} : _c,
    _d = _a.collection,
    collection = _d === void 0 ? {} : _d,
    _e = _a.realtime,
    realtime = _e === void 0 ? false : _e,
    withInstancesOf = _a.withInstancesOf;
  var requests = (0, vue_1.ref)({});
  var indexItems = (0, vue_1.ref)({});
  var realtimeCallbacks = [];
  function indexUpdate(items) {
    indexItems.value = __assign(__assign({}, indexItems.value), Object.fromEntries(items.map(function (item) {
      return [item.id, new withInstancesOf(item)];
    })));
  }
  function indexRemove(id) {
    indexItems.value = Object.fromEntries(Object.entries(indexItems.value).filter(function (_a) {
      var key = _a[0];
      return key !== id;
    }));
  }
  function useItems(_a) {
    if (_a === void 0) {
      _a = {};
    }
    var _b = _a.page,
      initialPage = _b === void 0 ? 1 : _b,
      _c = _a.perPage,
      initialPerPage = _c === void 0 ? 25 : _c,
      restOfParameters = __rest(_a, ["page", "perPage"]);
    var parameters = (0, vue_1.ref)(__assign({
      per_page: initialPerPage,
      page: initialPage
    }, restOfParameters));
    var checksum = (0, vue_1.computed)(function () {
      return hash(parameters.value);
    });
    var items = (0, vue_1.computed)(function () {
      var fetchObject = requests.value[checksum.value];
      if (!fetchObject) return [];
      var buffer = [];
      for (var _i = 0, _a = fetchObject.ids; _i < _a.length; _i++) {
        var id = _a[_i];
        var item = indexItems.value[id];
        if (!item) continue;
        if (!itemMatchesFilters(item, parameters)) continue;
        buffer.push(new withInstancesOf(item));
      }
      return buffer;
    });
    var meta = (0, vue_1.computed)(function () {
      var _a;
      return (_a = requests.value[checksum.value]) === null || _a === void 0 ? void 0 : _a.meta;
    });
    var pending = (0, vue_1.computed)(function () {
      return items.value.length ? false : fetchPending.value;
    });
    var _d = (0, fetch_1.useFetch)({
        endpoint: endpoint,
        params: parameters,
        callback: function (response, context) {
          var _a;
          requests.value = __assign(__assign({}, requests.value), (_a = {}, _a[hash(context)] = {
            ids: response.data.map(function (item) {
              return item.id;
            }),
            meta: response.meta
          }, _a));
          indexUpdate(response.data);
        }
      }),
      refetch = _d.refetch,
      fetchPending = _d.pending;
    realtimeCallbacks.push(function (item) {
      var _a;
      var checksumKey = hash(parameters.value);
      var fetchObject = requests.value[checksumKey];
      if (!itemMatchesFilters(item, parameters)) return;
      var ids = __spreadArray([], new Set(__spreadArray(__spreadArray([], fetchObject.ids, true), [item.id], false)), true);
      requests.value = __assign(__assign({}, requests.value), (_a = {}, _a[checksumKey] = {
        ids: ids,
        meta: fetchObject.meta
      }, _a));
    });
    var _e = (0, core_1.toRefs)(parameters),
      page = _e.page,
      perPage = _e.per_page;
    return {
      items: items,
      pending: pending,
      meta: meta,
      page: page,
      perPage: perPage,
      refetch: refetch,
      params: parameters
    };
  }
  var supabase = (0, client_1.useClient)();
  if (realtime) supabase.channel([entity, "listen"].join("-")).on("postgres_changes", {
    event: "*",
    schema: "public",
    table: entity
  }, function (payload) {
    if ((0, radash_1.isEmpty)(payload.new)) return;
    var updatedItem = payload.new;
    indexUpdate([updatedItem]);
    for (var _i = 0, realtimeCallbacks_1 = realtimeCallbacks; _i < realtimeCallbacks_1.length; _i++) {
      var callback = realtimeCallbacks_1[_i];
      callback(updatedItem);
    }
  }).subscribe();
  return {
    useItems: useItems,
    byId: function (id) {
      var _a = useItems({
          "id.eq": id
        }),
        items = _a.items,
        pending = _a.pending,
        context = __rest(_a, ["items", "pending"]);
      var rawId = function () {
        return isRef(id) ? id.value : id;
      };
      return __assign({
        item: (0, vue_1.computed)(function () {
          var _a;
          return (_a = indexItems.value[rawId()]) !== null && _a !== void 0 ? _a : items.value[0];
        }),
        pending: (0, vue_1.computed)(function () {
          var _a;
          return (_a = !indexItems.value[rawId()]) !== null && _a !== void 0 ? _a : pending.value;
        })
      }, context);
    },
    byIds: function (ids) {
      var _a = useItems({
          "id.in": "(".concat(__spreadArray([], ids, true).join(","), ")")
        }),
        items = _a.items,
        pending = _a.pending,
        context = __rest(_a, ["items", "pending"]);
      var preItems = [];
      for (var _i = 0, ids_1 = ids; _i < ids_1.length; _i++) {
        var id = ids_1[_i];
        indexItems.value[id] && preItems.push(indexItems.value[id]);
      }
      return __assign({
        items: (0, vue_1.computed)(function () {
          return items.value.length === 0 ? preItems : items.value;
        }),
        pending: (0, vue_1.computed)(function () {
          return preItems.length === 0 && pending.value;
        })
      }, context);
    },
    single: single,
    collection: collection,
    singular: (0, pluralize_1.singular)(entity)
  };
}
exports.useIndex = useIndex;
var valueMatches = {
  eq: function (fieldValue, filterValue) {
    return fieldValue === filterValue;
  },
  in: function (fieldValue, filterValue) {
    return filterValue.replace("(", "").replace(")", "").split(",").includes(fieldValue);
  },
  gt: function (fieldValue, filterValue) {
    return typeof filterValue === "string" && typeof fieldValue === "string" ? luxon_1.DateTime.fromISO(fieldValue).diff(luxon_1.DateTime.fromISO(filterValue)).milliseconds > 0 : fieldValue > filterValue;
  },
  lte: function (fieldValue, filterValue) {
    return typeof filterValue === "string" && typeof fieldValue === "string" ? luxon_1.DateTime.fromISO(fieldValue).diff(luxon_1.DateTime.fromISO(filterValue)).milliseconds <= 0 : fieldValue <= filterValue;
  },
  ov: function (fieldValue, filterValue) {
    if (!filterValue) return true;
    var deserializedFilterValue = JSON.parse(filterValue.replace(/{/g, "[").replace(/}/g, "]"));
    for (var _i = 0, deserializedFilterValue_1 = deserializedFilterValue; _i < deserializedFilterValue_1.length; _i++) {
      var value = deserializedFilterValue_1[_i];
      if (fieldValue.includes(value)) return true;
    }
    return false;
  }
};
var itemMatchesFilters = function (item, parameters) {
  for (var property in (0, radash_1.omit)(parameters.value, ["page", "per_page"])) {
    if (!property.includes(".")) continue;
    var _a = property.split("."),
      key = _a[0],
      op = _a[1];
    var value = parameters.value[property];
    if (!valueMatches[op](item[key], value)) return false;
  }
  return true;
};