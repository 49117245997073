"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.debouncedHaptic = void 0;
var haptics_1 = require("@capacitor/haptics");
var radash_1 = require("radash");
exports.debouncedHaptic = (0, radash_1.debounce)({ delay: 100 }, function () { return haptics_1.Haptics.impact({ style: haptics_1.ImpactStyle.Light }); });
var vHover = {
    mounted: function (element) {
        element.__hoverCallback = function () {
            element.style.transition = "transform .3s";
            element.style.transform = "scale(.875)";
            (0, exports.debouncedHaptic)();
            document.addEventListener("touchend", function () {
                (0, exports.debouncedHaptic)();
                element.style.transform = "none";
            }, { once: true });
        };
        element.addEventListener("touchstart", element.__hoverCallback);
    },
    beforeUnmount: function (element, binding, vnode, previousVnode) {
        element.removeEventListener("touchstart", element.__hoverCallback);
    },
};
exports.default = vHover;
