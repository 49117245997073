"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.profile = exports.userId = void 0;
var vue_1 = require("vue");
var client_1 = require("./client");
var vue_router_1 = require("vue-router");
var users = require("@core/supabase/user");
exports.userId = (0, vue_1.ref)();
exports.profile = (0, vue_1.computed)(function () { return exports.userId.value ? users.byId(exports.userId) : undefined; });
var useUser = function () {
    var user = (0, vue_1.ref)({ id: "", email: "", providers: [] });
    var authenticated = (0, vue_1.ref)("unknown");
    var router = (0, vue_router_1.useRouter)();
    var fetchUser = function () {
        var client = (0, client_1.useClient)();
        client.auth.getUser().then(function (response) {
            var data = response.data;
            if (data.user) {
                var _a = data.user, id = _a.id, email = _a.email, identities = _a.identities;
                user.value = { id: id, email: email !== null && email !== void 0 ? email : "" };
                if (identities) {
                    var providers = identities.map(function (identity) { return identity.provider; });
                    user.value.providers = providers;
                }
                exports.userId.value = id;
            }
            authenticated.value = !!data.user;
        }).catch(function () { return authenticated.value = false; });
    };
    var logout = function () { return __awaiter(void 0, void 0, void 0, function () {
        var client, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    client = (0, client_1.useClient)();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, client.auth.signOut()];
                case 2:
                    _a.sent();
                    router.push("/?skipwalkthrough=true");
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.error("Error signing out:", error_1.message);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    fetchUser();
    return { user: user, logout: logout, authenticated: authenticated, profile: exports.profile };
};
exports.default = useUser;
